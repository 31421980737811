import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { getGatsbyImageData, gridSizes, srcSizes } from '../../common/gatsbyImageData'
import IconLoaders from '../../icons/IconLoaders'
import PannableImage from '../products/PannableImage'

const ImageGalleryPopup = ({ showImage, setShowImage, images, product }) => {
  const [zoomIn, setZoomIn] = React.useState(false)
  // Function to handle key presses
  const handleKeyPress = e => {
    e.preventDefault()
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        // Show the previous image
        setShowImage(prevIndex => (prevIndex > 0 ? prevIndex - 1 : images.length - 1))
        break
      case 'ArrowDown':
      case 'ArrowRight':
        // Show the next image
        setShowImage(prevIndex => (prevIndex < images.length - 1 ? prevIndex + 1 : 0))
        break
      case 'Escape':
        // Close the popup
        setShowImage(false)
        break
      default:
        break
    }
  }
  React.useEffect(() => {
    if (typeof window === 'undefined') return
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [showImage, images.length])

  const imageData = React.useMemo(
    () => getGatsbyImageData(images[showImage], srcSizes),
    [images[showImage], srcSizes]
  )
  imageData.placeholder = {
    children: <IconLoaders type={product?.productType} />,
    className: 'm-1',
  }
  return (
    <div className="animate-fade-in hidden md:flex fixed inset-0 z-popup justify-center items-center bg-gray-400 bg-opacity-40 backdrop-blur-sm">
      <Helmet bodyAttributes={{ class: 'overflow-hidden' }} />
      {zoomIn && (
        <PannableImage
          className="absolute inset-0 z-10 bg-white overflow-auto cursor-grab"
          onClick={() => setZoomIn(false)}
        >
          <GatsbyImage
            image={imageData}
            alt={product.title}
            imgStyle={{ objectFit: 'contain' }}
            className={
              'aspect-square w-full h-full min-w-[2000px] min-h-[2000px] pointer-events-none '
            }
            loading="eager"
          />
          <div className="fixed top-5 text-center cursor-pointer right-5 h-8 w-8 rounded-full border-2 border-gray-400 text-lg font-black text-gray-400 hover:border-gray-600 hover:bg-gray-100 hover:text-gray-600">
            &#10005;
          </div>
        </PannableImage>
      )}
      <div onClick={() => setShowImage(false)} className="absolute inset-0 z-0 " />
      <div className="overflow-hidden grid portrait:grid-rows-[1fr_auto] landscape:grid-rows-1 landscape:grid-cols-[1fr_auto] landscape:sticky landscape:top-20 max-w-[90vw] max-h-[90vh] bg-white p-2 rounded-xl shadow-md">
        <div
          className=" max-w-full max-h-full portrait:md:w-10/12 portrait:lg:w-[70vw] m-auto landscape:w-[75vh] h-auto !aspect-square p-4 overflow-hidden cursor-zoom-in  "
          onClick={() => setZoomIn(true)}
        >
          <GatsbyImage
            image={imageData}
            alt={product.title}
            imgStyle={{ objectFit: 'contain' }}
            className={'aspect-square w-full h-full max-w-full max-h-full'}
            loading="eager"
          />
        </div>
        <div className="landscape:w-36 portrait:h-36 p-3 m-2 flex overflow-auto landscape:space-y-7 portrait:space-x-5 landscape:flex-col portrait:flex-row ">
          {images.map((image, index) => {
            const imageData = React.useMemo(
              () => getGatsbyImageData(image, gridSizes, '9rem'),
              [image, gridSizes]
            )
            imageData.placeholder = {
              children: <IconLoaders type={product?.productType} />,
              className: 'm-1',
            }
            return (
              <div
                className="grid grid-[1/1] aspect-square cursor-zoom-in landscape:w-full max-w-36 portrait:h-full max-h-[7.5rem] border p-1 hover:border-b-red-700 duration-200"
                key={index}
                onClick={() => setShowImage(index)}
              >
                <GatsbyImage
                  image={imageData}
                  alt={product.title}
                  imgStyle={{ objectFit: 'contain' }}
                  className={'aspect-square w-full h-full'}
                  loading="eager"
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
ImageGalleryPopup.propTypes = {
  showImage: PropTypes.number,
  setShowImage: PropTypes.func,
  images: PropTypes.array,
  product: PropTypes.object,
}

export default ImageGalleryPopup
