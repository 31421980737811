import React, { useRef, useState } from 'react'

const PannableImage = ({ children, className, onClick }) => {
  const containerRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [moved, setMoved] = useState(false)

  const handleMouseDown = e => {
    setMoved(false)
    setIsDragging(true)
    containerRef.current.style.cursor = 'grabbing'
    setStartX(e.pageX - containerRef.current.offsetLeft)
    setStartY(e.pageY - containerRef.current.offsetTop)
    setScrollLeft(containerRef.current.scrollLeft)
    setScrollTop(containerRef.current.scrollTop)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
    containerRef.current.style.cursor = 'grab'
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    containerRef.current.style.cursor = 'grab'
  }

  const handleMouseMove = e => {
    if (!isDragging) return
    e.preventDefault()
    const x = e.pageX - containerRef.current.offsetLeft
    const y = e.pageY - containerRef.current.offsetTop
    const walkX = (x - startX) * 1 // Adjust the multiplier for faster/slower panning
    const walkY = (y - startY) * 1 // Adjust the multiplier for faster/slower panning
    if (Math.abs(walkX) + Math.abs(walkY) > 40) setMoved(true)
    containerRef.current.scrollLeft = scrollLeft - walkX
    containerRef.current.scrollTop = scrollTop - walkY
  }

  const handleClick = () => {
    if (!moved) {
      onClick()
    }
  }

  return (
    <div
      ref={containerRef}
      className={className}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

export default PannableImage
